<template>
  <div class="content">
    <div class="help">
      <i class="el-icon-info"></i>
      <span>已选择类目：</span>
      <span class="active" v-show="chooseName">{{chooseName}}</span>
    </div>
    <div class="choose flex-between">
      <Select width="280px" :selects="one" @select="oneSelect">
        <template v-slot:head>类目列表：{{cName[0] || ''}}</template>
      </Select>
      <el-divider class="divider" direction="vertical"></el-divider>
      <Select width="280px" :selects="two" @select="twoSelect">
        <template v-slot:head>子类目列表：{{cName[1] || ''}}</template>
      </Select>
      <el-divider class="divider" direction="vertical"></el-divider>
      <Select width="280px" :selects="three" @select="threeSelect">
        <template v-slot:head>叶子类目列表：{{cName[2] || ''}}</template>
      </Select>
    </div>
    <div class="confirm">
      <el-button class="tb-button" type="primary" @click="tonext">下一步发布商品</el-button>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import Select from '@/components/Select'
export default {
  name: 'ProductAdd',
  components: {
    Select
  },
  data() {
    return {
      one: [],
      choose: ['', '', '']
    }
  },
  watch:{
    categoryOptions() {
      this.one = JSON.parse(JSON.stringify(this.categoryOptions))
    }
  },
  computed: {
    cName() {
      let name = []
      const dealData = (arr) => {
        arr.forEach(item => {
          if (item.checked) name.push(item.label)
          if (item.children) dealData(item.children)
        })
      }
      dealData(this.one)
      return name
    },
    chooseName() {
      return this.cName.join(' > ')
    },
    two() {
      const item = this.one.find(item => item.checked)
      if (item) return item.children
      return []
    },
    three() {
      const item = this.two.find(item => item.checked)
      if (item) return item.children
      return []
    },
    ...mapState([
      'categoryOptions'
    ])
  },
  mounted() {
    this.one = JSON.parse(JSON.stringify(this.categoryOptions))
  },
  methods: {
    setFalse(arr) {
      arr.children.forEach(item => {
        item.checked = false
        if (item.children) this.setFalse(item.children)
      })
    },
    oneSelect(id) {
      this.one.forEach(item => {
        item.checked = item.id === id
        if (item.children) {
          item.children.forEach(child => {
            child.checked = false
            if (child.children) {
              child.children.forEach(grandson => {
                grandson.checked = false
              })
            }
          })
        }
      })
      this.choose[0] = id
      this.choose[1] = ''
      this.choose[2] = ''
    },
    twoSelect(id) {
      this.two.forEach(item => {
        item.checked = item.id === id
        if (item.children) {
          item.children.forEach(child => {
            child.checked = false
          })
        }
      })
      this.choose[1] = id
      this.choose[2] = ''
    },
    threeSelect(id) {
      this.three.forEach(item => item.checked = item.id === id)
      this.choose[2] = id
    },
    tonext() {
      let [a, b, c] = this.choose
      if (!a || !b || !c) {
        this.one.forEach(oneCategory => {
          if (oneCategory.checked) {
            a = oneCategory.id
            this.choose[0] = a
            oneCategory.children.forEach(twoCategory => {
              if (twoCategory.checked) {
                b = twoCategory.id
                this.choose[1] = b
                twoCategory.children.forEach(threeCategory => {
                  if (threeCategory.checked) {
                    c = threeCategory.id
                    this.choose[2] = c
                  }
                })
              }
            })
          }
        })
      }
      if (!a || !b || !c) {
        this.$message.error('请选择类目')
        return
      }
      this.$store.commit('setCategoryOptions', this.one)
      this.$router.push('/product/publish?cid=' + c)
    }
  }
}
</script>

<style scoped lang="scss">
.help {
  width: 970px;
  padding: 9px 16px;
  margin: 0 auto;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  background-color: #E6F7FF;
  border: 1px solid #BAE7FF;
  border-radius: 4px;
  .el-icon-info {
    color: #1890FF;
    margin-right: 8px;
  }
  .active {
    color: #1890FF;
  }
}
.choose {
  height: auto;
  width: 970px;
  margin: 24px auto;
  .divider {
    height: 441px;
  }
}
.confirm {
  text-align: center;
}
</style>
