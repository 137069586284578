<template>
  <div class="select" :style="{'width': width}">
    <div class="head">
      <slot name="head"></slot>
    </div>
    <div class="search" v-if="canSearch">
      <el-input suffix-icon="el-icon-search" v-model="search" placeholder="请输入搜索内容"></el-input>
    </div>
    <div class="box">
      <template v-if="selects.length !== 0">
        <el-radio-group class="single box-checkbox flex" v-model="single">
          <el-radio :label="item.value" :checked="item.checked" v-for="item in selects" :key="item.id">{{item.label}}</el-radio>
        </el-radio-group>
      </template>
      <div class="no-more" v-else>
        暂无数据
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Select',
  props: {
    width: String,
    canSearch: {
      default: true,
      type: Boolean
    },
    selects: {
      default: () => {
        return []
      },
      type: Array
    }
  },
  data() {
    return {
      search: ''
    }
  },
  computed: {
    single: {
      get() {
        const item = this.selects.find(item => item.checked)
        if (item) return item.value
        return ''
      },
      set(val) {
        this.$emit('select', val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.select {
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  .head {
    height: 40px;
    line-height: 40px;
    padding: 0 12px;
    color: rgba(0, 0, 0, 0.65);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .search {
    padding: 0 12px;
    margin: 8px 0;
  }
  .box {
    height: 360px;
    overflow-y: auto;
    box-sizing: border-box;
    &::-webkit-scrollbar{
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.45);
      border-radius: 20px;
    }
    &-checkbox {
      flex-direction: column;
      .el-radio {
        padding: 0 12px;
        line-height: 32px;
      }
      .el-radio+.el-radio,
      .el-radio:first-child {
        margin-right: 0;
      }
      .el-radio.is-checked {
        background-color: #E6F7FF;
      }
    }
    .single {
      width: 100%;
      .el-radio {
        width: 100%;
        box-sizing: border-box;
      }
    }
    .no-more {
      text-align: center;
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
</style>
